a, h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, .x-breadcrumb-wrap a:hover, .widget ul li a:hover, .widget ol li a:hover, .widget.widget_text ul li a, .widget.widget_text ol li a, .widget_nav_menu .current-menu-item > a, .x-accordion-heading .x-accordion-toggle:hover, .x-comment-author a:hover, .x-comment-time:hover, .x-recent-posts a:hover .h-recent-posts {
  color: #185dab;
}

a:hover, .widget.widget_text ul li a:hover, .widget.widget_text ol li a:hover, .x-twitter-widget ul li a:hover {
  color: #ad0138;
}

.rev_slider_wrapper, a.x-img-thumbnail:hover, .x-slider-container.below, .page-template-template-blank-3-php .x-slider-container.above, .page-template-template-blank-6-php .x-slider-container.above {
  border-color: #185dab;
}

.entry-thumb:before, .x-pagination span.current, .woocommerce-pagination span[aria-current], .flex-direction-nav a, .flex-control-nav a:hover, .flex-control-nav a.flex-active, .mejs-time-current, .x-dropcap, .x-skill-bar .bar, .x-pricing-column.featured h2, .h-comments-title small, .x-entry-share .x-share:hover, .x-highlight, .x-recent-posts .x-recent-posts-img:after {
  background-color: #185dab;
}

.x-nav-tabs > .active > a, .x-nav-tabs > .active > a:hover {
  box-shadow: inset 0 3px #185dab;
}

.x-main {
  width: 69.537%;
}

.x-sidebar {
  width: 25.5369%;
}

.x-comment-author, .x-comment-time, .comment-form-author label, .comment-form-email label, .comment-form-url label, .comment-form-rating label, .comment-form-comment label, .widget_calendar #wp-calendar caption, .widget.widget_rss li .rsswidget {
  font-family: Crimson Pro, serif;
  font-weight: 400;
}

.p-landmark-sub, .p-meta, input, button, select, textarea {
  font-family: Montserrat, sans-serif;
}

.widget ul li a, .widget ol li a, .x-comment-time {
  color: #1d1d1e;
}

.widget_text ol li a, .widget_text ul li a {
  color: #185dab;
}

.widget_text ol li a:hover, .widget_text ul li a:hover {
  color: #ad0138;
}

.comment-form-author label, .comment-form-email label, .comment-form-url label, .comment-form-rating label, .comment-form-comment label, .widget_calendar #wp-calendar th, .p-landmark-sub strong, .widget_tag_cloud .tagcloud a:hover, .widget_tag_cloud .tagcloud a:active, .entry-footer a:hover, .entry-footer a:active, .x-breadcrumbs .current, .x-comment-author, .x-comment-author a {
  color: #272727;
}

.widget_calendar #wp-calendar th {
  border-color: #272727;
}

.h-feature-headline span i {
  background-color: #272727;
}

html {
  font-size: 14px;
}

@media (min-width: 480px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 767px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 979px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

body {
  color: #1d1d1e;
  background-color: #fff;
  font-style: normal;
  font-weight: 400;
}

.w-b {
  font-weight: 400 !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: Crimson Pro, serif;
  font-style: normal;
  font-weight: 400;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  letter-spacing: -.035em;
}

.w-h {
  font-weight: 400 !important;
}

.x-container.width {
  width: 88%;
}

.x-container.max {
  max-width: 1200px;
}

.x-bar-content.x-container.width {
  flex-basis: 88%;
}

.x-main.full {
  float: none;
  width: auto;
  display: block;
}

@media (max-width: 978.98px) {
  .x-main.full, .x-main.left, .x-main.right, .x-sidebar.left, .x-sidebar.right {
    float: none;
    display: block;
    width: auto !important;
  }
}

.entry-header, .entry-content {
  font-size: 1rem;
}

body, input, button, select, textarea {
  font-family: Montserrat, sans-serif;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a, blockquote {
  color: #272727;
}

.cfc-h-tx {
  color: #272727 !important;
}

.cfc-h-bd {
  border-color: #272727 !important;
}

.cfc-h-bg {
  background-color: #272727 !important;
}

.cfc-b-tx {
  color: #1d1d1e !important;
}

.cfc-b-bd {
  border-color: #1d1d1e !important;
}

.cfc-b-bg {
  background-color: #1d1d1e !important;
}

.x-btn, .button, [type="submit"] {
  color: #ffffffe6;
  text-shadow: 0 .075em .075em #00000080;
  background-color: #185dab;
  border-color: #0000;
  border-radius: .25em;
  padding: .579em 1.105em .842em;
  font-size: 19px;
}

.x-btn:hover, .button:hover, [type="submit"]:hover {
  color: #fff;
  text-shadow: 0 .075em .075em #00000080;
  background-color: #185dab;
  border-color: #0000;
}

.x-btn.x-btn-real, .x-btn.x-btn-real:hover {
  text-shadow: 0 .075em .075em #000000a6;
  margin-bottom: .25em;
  box-shadow: 0 .25em #a71000, 0 4px 9px #000000bf;
}

.x-btn.x-btn-flat, .x-btn.x-btn-flat:hover {
  text-shadow: 0 .075em .075em #000000a6;
  box-shadow: none;
  margin-bottom: 0;
}

.x-btn.x-btn-transparent, .x-btn.x-btn-transparent:hover {
  text-shadow: none;
  text-transform: uppercase;
  box-shadow: none;
  background-color: #0000;
  border-width: 3px;
  margin-bottom: 0;
}

@font-face {
  font-family: FontAwesomePro;
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.d8d3cd62.woff2") format("woff2"), url("fa-solid-900.052cba49.woff") format("woff"), url("fa-solid-900.f5f19bd9.ttf") format("truetype");
}

[data-x-fa-pro-icon] {
  font-family: FontAwesomePro !important;
}

[data-x-fa-pro-icon]:before {
  content: attr(data-x-fa-pro-icon);
}

[data-x-icon], [data-x-icon-o], [data-x-icon-l], [data-x-icon-s], [data-x-icon-b], [data-x-fa-pro-icon], [class*="cs-fa-"] {
  text-decoration: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
}

[data-x-icon].left, [data-x-icon-o].left, [data-x-icon-l].left, [data-x-icon-s].left, [data-x-icon-b].left, [data-x-fa-pro-icon].left, [class*="cs-fa-"].left {
  margin-right: .5em;
}

[data-x-icon].right, [data-x-icon-o].right, [data-x-icon-l].right, [data-x-icon-s].right, [data-x-icon-b].right, [data-x-fa-pro-icon].right, [class*="cs-fa-"].right {
  margin-left: .5em;
}

[data-x-icon]:before, [data-x-icon-o]:before, [data-x-icon-l]:before, [data-x-icon-s]:before, [data-x-icon-b]:before, [data-x-fa-pro-icon]:before, [class*="cs-fa-"]:before {
  line-height: 1;
}

@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.d8d3cd62.woff2") format("woff2"), url("fa-solid-900.052cba49.woff") format("woff"), url("fa-solid-900.f5f19bd9.ttf") format("truetype");
}

[data-x-icon], [data-x-icon-s], [data-x-icon][class*="cs-fa-"] {
  font-weight: 900;
  font-family: FontAwesome !important;
}

[data-x-icon]:before, [data-x-icon][class*="cs-fa-"]:before {
  content: attr(data-x-icon);
}

[data-x-icon-s]:before {
  content: attr(data-x-icon-s);
}

@font-face {
  font-family: FontAwesomeRegular;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.54dd6033.woff2") format("woff2"), url("fa-regular-400.2df5242f.woff") format("woff"), url("fa-regular-400.1106437c.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesomePro;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.54dd6033.woff2") format("woff2"), url("fa-regular-400.2df5242f.woff") format("woff"), url("fa-regular-400.1106437c.ttf") format("truetype");
}

[data-x-icon-o] {
  font-family: FontAwesomeRegular !important;
}

[data-x-icon-o]:before {
  content: attr(data-x-icon-o);
}

@font-face {
  font-family: FontAwesomeLight;
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("fa-light-300.120a8293.woff2") format("woff2"), url("fa-light-300.ff565dba.woff") format("woff"), url("fa-light-300.30d5e4b8.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesomePro;
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("fa-light-300.120a8293.woff2") format("woff2"), url("fa-light-300.ff565dba.woff") format("woff"), url("fa-light-300.30d5e4b8.ttf") format("truetype");
}

[data-x-icon-l] {
  font-weight: 300;
  font-family: FontAwesomeLight !important;
}

[data-x-icon-l]:before {
  content: attr(data-x-icon-l);
}

@font-face {
  font-family: FontAwesomeBrands;
  font-style: normal;
  font-weight: normal;
  font-display: block;
  src: url("fa-brands-400.78d7f9b3.woff2") format("woff2"), url("fa-brands-400.0333f839.woff") format("woff"), url("fa-brands-400.019950e2.ttf") format("truetype");
}

[data-x-icon-b] {
  font-family: FontAwesomeBrands !important;
}

[data-x-icon-b]:before {
  content: attr(data-x-icon-b);
}

.m1f-0.x-bar {
  width: 100%;
  height: auto;
  background-color: #fff;
  border: 0 solid #0000;
  border-top-style: double;
  border-bottom: 2px solid #1d1d1e;
  font-size: 1em;
  box-shadow: 0 -.1em 2.5em #1d1d1e40;
}

.m1f-0 .x-bar-content {
  height: auto;
  flex-wrap: wrap;
  place-content: center;
}

.m1f-0.x-bar-space {
  height: auto;
  font-size: 1em;
}

.m1f-1.x-bar {
  z-index: 9999;
}

.m1f-1 .x-bar-content {
  flex-direction: row;
  flex: 0 100%;
  align-items: center;
}

.m1f-1.x-bar-outer-spacers:after, .m1f-1.x-bar-outer-spacers:before {
  height: 2em;
  flex-basis: 2em;
  width: 2em !important;
}

.m1f-2.x-bar {
  height: 4em;
  border: 0;
  font-size: 16px;
}

.m1f-2 .x-bar-content {
  height: 4em;
  justify-content: space-between;
}

.m1f-2.x-bar-space {
  height: 4em;
  font-size: 16px;
}

.m1f-3.x-bar-container {
  max-width: 1200px;
  flex-flow: wrap;
  place-content: center space-between;
}

.m1f-4.x-bar-container {
  z-index: 1;
  border: 0;
  flex: 1 0 auto;
  align-items: center;
  font-size: 1em;
}

.m1f-5.x-bar-container {
  flex-direction: row-reverse;
  justify-content: center;
  margin: 0 0 20px;
}

.m1f-6.x-image {
  background-color: #0000;
  border: 0;
  font-size: 1em;
}

.m1f-7.x-text {
  border: 0;
  margin: .5em 2.25em .5em 0;
  font-size: 2.5em;
}

.m1f-7.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Crimson Pro, serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  overflow: hidden;
}

.m1f-8.x-anchor {
  width: 2.75em;
  height: 2.75em;
  background-color: #0000;
  margin: 0 0 0 -.7em;
  font-size: 1em;
}

.m1f-8.x-anchor .x-toggle {
  color: #000;
}

.m1f-8.x-anchor:hover .x-toggle, .m1f-8.x-anchor[class*="active"] .x-toggle, [data-x-effect-provider*="colors"]:hover .m1f-8.x-anchor .x-toggle {
  color: #00000080;
}

.m1f-8.x-anchor .x-toggle-burger {
  width: 16em;
  margin: 4em 0;
  font-size: .1em;
}

.m1f-8.x-anchor .x-toggle-burger-bun-t {
  transform: translate3d(0, -4em, 0);
}

.m1f-8.x-anchor .x-toggle-burger-bun-b {
  transform: translate3d(0, 4em, 0);
}

.m1f-9.x-anchor {
  border: 0;
}

.m1f-9.x-anchor .x-anchor-content {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.m1f-a.x-anchor {
  background-color: #185dab;
  border-radius: .35em;
  font-size: 1.25em;
  box-shadow: 0 .15em .65em 0 #00000040;
}

.m1f-a.x-anchor .x-anchor-content {
  padding: .575em .85em;
}

.m1f-a.x-anchor .x-anchor-text {
  margin: 5px;
}

.m1f-a.x-anchor .x-anchor-text-primary {
  color: #ffffffd9;
  font-family: Crimson Pro, serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.m1f-a.x-anchor:hover .x-anchor-text-primary, .m1f-a.x-anchor[class*="active"] .x-anchor-text-primary, [data-x-effect-provider*="colors"]:hover .m1f-a.x-anchor .x-anchor-text-primary {
  color: #fff;
}

.m1f-b {
  font-size: 16px;
  transition-duration: .5s;
}

.m1f-b .x-off-canvas-bg {
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.m1f-b .x-off-canvas-close {
  width: 1em;
  height: 1em;
  color: #00000080;
  font-size: 4em;
  transition-duration: .3s, .5s, .5s;
  transition-timing-function: ease-in-out, cubic-bezier(.4, 0, .2, 1), cubic-bezier(.4, 0, .2, 1);
}

.m1f-b .x-off-canvas-close:focus, .m1f-b .x-off-canvas-close:hover {
  color: #000;
}

.m1f-b .x-off-canvas-content {
  background-color: #ffffffe8;
  border: 0;
  padding: 4em;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0 0 2em 0 #00000040;
}

body:not(.hoc-home) .e51-9 {
  display: none;
}

.mw-0.x-bar {
  height: auto;
  z-index: 0;
  background-color: #fff;
  border: 0 #0000;
  border-top: 2px solid #1d1d1e;
  padding: 2em 0;
  font-size: 1em;
}

.mw-0 .x-bar-content {
  height: auto;
  flex-flow: wrap;
  flex: 0 100%;
  place-content: center;
  align-items: center;
}

.mw-0.x-bar-outer-spacers:after, .mw-0.x-bar-outer-spacers:before {
  height: 0;
  flex-basis: 0;
  width: 0 !important;
}

.mw-0.x-bar-space {
  font-size: 1em;
}

.mw-1.x-bar-container {
  flex-direction: row;
  justify-content: flex-start;
}

.mw-2.x-bar-container {
  z-index: 1;
  border: 0;
  flex: 0 auto;
  align-items: center;
  font-size: 1em;
}

.mw-3.x-bar-container {
  flex-flow: column wrap;
  place-content: center;
}

.mw-4.x-image {
  background-color: #0000;
  border: 0;
  padding: 2rem;
  font-size: 1em;
}

.mw-5 {
  flex-flow: wrap;
  flex: 0 auto;
  place-content: stretch space-between;
  align-self: center;
  align-items: stretch;
  font-size: 1em;
}

.mw-5 > li, .mw-5 > li > a {
  flex: 0 auto;
}

.mw-6 .x-dropdown {
  width: 14em;
  background-color: #fff;
  border: 0;
  font-size: 16px;
  transition-duration: .5s, .5s, 0s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0 .15em 2em 0 #00000026;
}

.mw-6 selector:not(.x-active) {
  transition-delay: 0s, 0s, .5s;
}

.mw-7.x-anchor {
  background-color: #0000;
  border: 0;
}

.mw-7.x-anchor .x-anchor-content {
  flex-direction: row;
  align-items: center;
  padding: .75em;
}

.mw-7.x-anchor .x-anchor-text {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.mw-7.x-anchor .x-anchor-text-primary {
  font-size: 1em;
  font-style: normal;
  line-height: 1;
}

.mw-7.x-anchor .x-anchor-sub-indicator {
  color: #000;
  margin: 5px;
  font-size: 1em;
}

.mw-7.x-anchor:hover .x-anchor-sub-indicator, .mw-7.x-anchor[class*="active"] .x-anchor-sub-indicator, [data-x-effect-provider*="colors"]:hover .mw-7.x-anchor .x-anchor-sub-indicator {
  color: #00000080;
}

.mw-8.x-anchor {
  font-size: 1.25em;
}

.mw-8.x-anchor .x-anchor-content {
  justify-content: center;
}

.mw-8.x-anchor .x-anchor-text {
  margin-right: 5px;
}

.mw-8.x-anchor .x-anchor-text-primary {
  color: #1d1d1e;
  font-family: Crimson Pro, serif;
  font-weight: 400;
}

.mw-8.x-anchor:hover .x-anchor-text-primary, .mw-8.x-anchor[class*="active"] .x-anchor-text-primary, [data-x-effect-provider*="colors"]:hover .mw-8.x-anchor .x-anchor-text-primary {
  color: #185dab;
}

.mw-8 > .is-primary {
  width: 40px;
  height: 2px;
  color: #185dab;
  transform-origin: 50%;
}

.mw-9.x-anchor {
  font-size: 1em;
}

.mw-9.x-anchor .x-anchor-content {
  justify-content: flex-start;
}

.mw-9.x-anchor .x-anchor-text {
  margin-right: auto;
}

.mw-9.x-anchor .x-anchor-text-primary {
  color: #000;
  font-family: inherit;
  font-weight: 400;
}

.mw-9.x-anchor:hover .x-anchor-text-primary, .mw-9.x-anchor[class*="active"] .x-anchor-text-primary, [data-x-effect-provider*="colors"]:hover .mw-9.x-anchor .x-anchor-text-primary {
  color: #00000080;
}

.mw-a.x-text {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #1d1d1e99;
  border: 0;
  padding: 1em;
  font-family: inherit;
  font-size: .75em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.mw-a.x-text > :first-child {
  margin-top: 0;
}

.mw-a.x-text > :last-child {
  margin-bottom: 0;
}

.mq-0.x-section {
  z-index: 1;
  border: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

.mq-1.x-section {
  padding-top: 45px;
  padding-bottom: 45px;
}

.mq-2.x-section {
  padding-top: 0;
  padding-bottom: 0;
}

.mq-3.x-section {
  background-color: #1d1d1e;
}

.mq-4.x-row {
  z-index: 1;
  margin-top: 0;
  margin-bottom: 0;
}

.mq-4 > .x-row-inner {
  place-content: stretch flex-start;
  align-items: stretch;
}

.mq-4 > .x-row-inner > * {
  flex-grow: 1;
}

.mq-5.x-row {
  margin-left: auto;
  margin-right: auto;
  padding: 1px;
  font-size: 1em;
}

.mq-5 > .x-row-inner {
  flex-direction: row;
  margin-left: calc(-.5rem - 1px);
  margin-right: calc(-.5rem - 1px);
}

.mq-5 > .x-row-inner > * {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mq-6.x-row {
  border-style: solid;
  border-width: 2px;
}

.mq-7.x-row {
  border-color: #1d1d1e;
}

.mq-8 > .x-row-inner {
  margin-top: calc(-.5rem - 1px);
  margin-bottom: calc(-.5rem - 1px);
}

.mq-8 > .x-row-inner > * {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mq-9 > .x-row-inner > :nth-child(n) {
  flex-basis: calc(100% - 1rem);
}

.mq-a.x-row {
  border-style: double;
  border-width: 6px;
}

.mq-b.x-row {
  border: 0;
}

.mq-c > .x-row-inner {
  margin-top: -1px;
  margin-bottom: -1px;
}

.mq-d > .x-row-inner > :nth-child(2n-1), .mq-d > .x-row-inner > :nth-child(2n) {
  flex-basis: calc(50% - 1rem);
}

.mq-f.x-row {
  z-index: auto;
  margin-top: 2em;
  margin-bottom: 2em;
}

.mq-f > .x-row-inner {
  place-content: center;
  align-items: center;
}

.mq-g.x-col {
  flex-wrap: wrap;
  display: flex;
}

.mq-h.x-col {
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.mq-i.x-col {
  justify-content: center;
}

.mq-j.x-col {
  z-index: 1;
  font-size: 1em;
}

.mq-k.x-col {
  border: 0;
}

.mq-l.x-col {
  padding: 15vw;
}

.mq-m.x-col {
  justify-content: flex-start;
}

.mq-n.x-col {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.mq-o.x-col {
  padding-left: 2rem;
  padding-right: 2rem;
}

.mq-p.x-col {
  text-align: center;
  background-color: #fff;
}

.mq-q.x-col {
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
}

.mq-r.x-col {
  justify-content: space-around;
}

.mq-t.x-col {
  border: 6px double #1d1d1e;
}

.mq-u.x-col {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mq-w.x-text {
  border: 0;
  font-size: 1em;
}

.mq-w.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-transform: none;
  font-style: normal;
  line-height: 1.4;
}

.mq-x.x-text .x-text-content-text-primary {
  font-family: Crimson Pro, serif;
  font-weight: 400;
}

.mq-y.x-text .x-text-content-text-primary {
  font-size: 3em;
}

.mq-z.x-text .x-text-content-text-primary {
  color: #000;
}

.mq-10.x-text .x-text-content-text-primary {
  color: #fffffff2;
}

.mq-11.x-text .x-text-content {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mq-11.x-text .x-text-content-text-primary {
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
}

.mq-11.x-text .x-graphic {
  margin: 0 .5em 0 0;
}

.mq-11.x-text .x-graphic-icon {
  width: auto;
  color: #fffffff2;
  border: 0;
  font-size: 1.25em;
}

.mq-12.x-text .x-text-content-text-primary {
  font-size: 2em;
}

.mq-13.x-div {
  border: 0;
  flex: 0 auto;
  font-size: 1em;
}

.mq-14.x-div {
  max-width: 500px;
}

.mq-15.x-div {
  margin-top: 2em;
}

.mq-16.x-div {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.mq-17.x-div {
  margin-top: 1em;
}

.mq-18.x-div {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.mq-19.x-text {
  margin-top: 2em;
}

.mq-1a.x-text {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.mq-1b.x-text {
  letter-spacing: 0;
  text-transform: none;
  border: 0;
  font-family: inherit;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.mq-1b.x-text > :first-child {
  margin-top: 0;
}

.mq-1b.x-text > :last-child {
  margin-bottom: 0;
}

.mq-1c.x-text {
  text-align: left;
  color: #000;
}

.mq-1d.x-text {
  width: 100%;
}

.mq-1e.x-text {
  color: #fffffff2;
}

.mq-1f.x-text {
  margin-top: 3em;
}

.mq-1h {
  color: #1d1d1e;
  border: 0;
  font-size: 1em;
}

.mq-1i {
  width: 100%;
  max-width: none;
  border: 0 solid #1d1d1e;
  border-top-width: 6px;
  font-size: 1em;
}

.mq-1j.x-anchor:hover .x-anchor-text-primary, .mq-1j.x-anchor[class*="active"] .x-anchor-text-primary, [data-x-effect-provider*="colors"]:hover .mq-1j.x-anchor .x-anchor-text-primary {
  color: #00000080;
}

.mq-1j.x-anchor .x-graphic {
  margin: 5px;
}

.mq-1j.x-anchor .x-graphic-icon {
  width: auto;
  border: 0;
}

.mq-1k.x-anchor {
  border: 0;
  font-size: 1em;
}

.mq-1k.x-anchor .x-anchor-content {
  justify-content: center;
  align-items: center;
}

.mq-1k.x-anchor .x-anchor-text {
  margin: 5px;
}

.mq-1k.x-anchor .x-anchor-text-primary {
  font-style: normal;
  line-height: 1;
}

.mq-1l.x-anchor {
  border-radius: .35em;
}

.mq-1l.x-anchor .x-anchor-content {
  padding: .575em .85em;
}

.mq-1l.x-anchor .x-anchor-text-primary {
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
}

.mq-1m.x-anchor .x-anchor-content {
  flex-direction: row-reverse;
}

.mq-1m.x-anchor .x-anchor-text-primary {
  color: #000;
}

.mq-1m.x-anchor .x-graphic-icon {
  color: #000;
  font-size: 1.25em;
}

.mq-1m.x-anchor:hover .x-graphic-icon, .mq-1m.x-anchor[class*="active"] .x-graphic-icon, [data-x-effect-provider*="colors"]:hover .mq-1m.x-anchor .x-graphic-icon {
  color: #00000080;
}

.mq-1n.x-anchor {
  width: 100%;
  height: 100%;
}

.mq-1n.x-anchor .x-anchor-content {
  flex-direction: column;
}

.mq-1n.x-anchor .x-anchor-text-primary {
  font-family: Crimson Pro, serif;
  font-size: 1.5em;
  font-weight: 400;
}

.mq-1n.x-anchor .x-graphic-icon {
  text-shadow: 0 4px 6px #1d1d1e;
  color: #fffffff2;
  font-size: 8em;
}

.mq-1n.x-anchor:hover .x-graphic-icon, .mq-1n.x-anchor[class*="active"] .x-graphic-icon, [data-x-effect-provider*="colors"]:hover .mq-1n.x-anchor .x-graphic-icon {
  color: #ffffff8c;
}

.mq-1o.x-anchor .x-anchor-text-primary {
  color: #fffffff2;
}

.mq-1p.x-anchor {
  margin-top: 0;
  margin-left: 1em;
  margin-right: 1em;
}

.mq-1q.x-anchor {
  background-color: #185dab;
  margin-bottom: 0;
  box-shadow: 0 .15em .65em 0 #00000040;
}

.mq-1q.x-anchor .x-anchor-content {
  flex-direction: row;
}

.mq-1q.x-anchor:hover, .mq-1q.x-anchor[class*="active"], [data-x-effect-provider*="colors"]:hover .mq-1q.x-anchor {
  background-color: #155093;
}

.mq-1r.x-anchor {
  margin-top: 1em;
  margin-left: 0;
  margin-right: 0;
}

.mq-1s {
  padding: 0 4.5em;
  font-size: 16px;
  transition-duration: .5s, 0s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.mq-1s:after, .mq-1s:before {
  width: 4.5em;
  height: 4.5em;
}

.mq-1s:not(.x-active) {
  transition-delay: 0s, .5s;
}

.mq-1s .x-modal-bg {
  background-color: #000000e6;
}

.mq-1s .x-modal-close {
  width: 1.5em;
  height: 1.5em;
  color: #ffffff80;
  font-size: 3em;
}

.mq-1s .x-modal-close:focus, .mq-1s .x-modal-close:hover {
  color: #fff;
}

.mq-1s .x-modal-content {
  background-color: #fff0;
  border: 0;
  padding: 2em;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0 .15em 2em 0 #00000040;
}

.mq-1t {
  padding: 300px 0 0;
  font-size: 1em;
}

.mq-1u.x-grid {
  grid-gap: 1rem 1rem;
  z-index: auto;
  border: 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  place-content: start center;
  place-items: stretch stretch;
  margin: 0 auto;
  font-size: 1em;
}

.mq-1v.x-cell {
  z-index: 1;
  grid-area: auto;
  place-content: center;
  align-items: center;
}

.mq-1w.x-cell {
  min-height: 300px;
  border: 2px solid #ffffffb3;
  flex-flow: column wrap;
  place-self: auto;
  font-size: 1em;
  display: flex;
}

.mq-1x.x-cell {
  z-index: auto;
  grid-area: 1 / 2 / 3 / 3;
  place-content: flex-start;
  align-items: flex-start;
  padding: 2em;
}

.mq-1y {
  max-width: 550px;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 3em;
}

.mq-1y .x-quote-text {
  text-align: center;
  color: #1d1d1e;
  font-family: Crimson Pro, serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.mq-1y .x-quote-cite {
  letter-spacing: .05em;
  text-align: center;
  color: #185dab;
  border: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: .75em 0 0;
  font-family: Crimson Pro, serif;
  font-size: .75em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}

.mq-1y .x-quote-cite-text {
  margin-right: -.05em;
}

.mq-1y .x-quote-cite-mark {
  margin: .1em .5em 0 0;
}

.mq-1y .x-quote-cite-mark .x-graphic-icon {
  width: auto;
  color: #185dab;
  border: 0;
  font-size: 1em;
}

@media screen and (max-width: 978.98px) {
  .mq-1x.x-cell {
    grid-row-start: 2;
    grid-column-start: 1;
  }
}

@media screen and (max-width: 766.98px) {
  .mq-e > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .mq-1u.x-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479.98px) {
  .mq-f > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }
}

.e26-4 {
  width: 100%;
  max-width: 400px;
  background: #ffffff80;
  padding: 2vw;
}

.e26-38 {
  background: linear-gradient(#282828 0%, #1d1d1e 100%);
}

.entry-wrap {
  box-shadow: none;
  justify-content: center;
  display: flex;
}

.x-btn, .button, [type="submit"] {
  text-shadow: none;
  font-family: Crimson Pro, serif;
}

.x-btn, .button, [type="submit"]:hover {
  text-shadow: none;
}

.x-section div.wpforms-container-full .wpforms-form input[type="submit"], .x-section div.wpforms-container-full .wpforms-form button[type="submit"], .x-section div.wpforms-container-full .wpforms-form .wpforms-page-button {
  color: #ffffffd9;
  background-color: #185dab;
  border: none;
  border-radius: 4px;
  padding: 1rem 2rem;
  font-family: Crimson Pro, serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.x-section div.wpforms-container-full .wpforms-form input[type="submit"]:hover, .x-section div.wpforms-container-full .wpforms-form button[type="submit"]:hover, .x-section div.wpforms-container-full .wpforms-form .wpforms-page-button:hover {
  color: #fff;
  background: #185dab;
  border: none;
}

/*# sourceMappingURL=index.1e019d12.css.map */
